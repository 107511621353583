import { types } from '../types';

export const INITIAL_STATE = {
    montobruto: 0,
    montoretencion: 0,
    montoneto: 0,
    montodescuentos: 0,
    montopagar: 0,
    reload: true
};

const calculatorReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

	switch (type) {

        case types.MONTO_ADD:

            state.montobruto = state.montobruto + payload.montobruto;
            state.montoretencion = state.montoretencion + payload.montoretencion;
            state.montoneto = state.montoneto + payload.montoneto;
            state.montodescuentos = state.montodescuentos + payload.montodescuentos;
            state.montopagar = state.montopagar + payload.montopagar;
            state.reload = payload.reload;

            return {
                ...state,
            }

        case types.MONTO_REMOVE:
            
            state.montobruto = state.montobruto - payload.montobruto;
            state.montoretencion = state.montoretencion - payload.montoretencion;
            state.montoneto = state.montoneto - payload.montoneto;
            state.montodescuentos = state.montodescuentos - payload.montodescuentos;
            state.montopagar = state.montopagar - payload.montopagar;
            state.reload = payload.reload;
            
            return {
                ...state,
            }

        case types.CALCULATOR_RELOAD:
                state.reload = payload.reload;
            return {
                ...state,
            }

        default:
            return state;
    }
};

export default calculatorReducer;
