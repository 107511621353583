import React , {useState   } from 'react';
import { Modal , Button , Form , Table } from 'react-bootstrap';
import { Notification } from 'rsuite';
import { validateEmail , formatNumber }  from '../../../helpers/Strings';
import  { SendMailDocumentosNoCalzados , getEMailProfesional } from '../../../api/end.points';

const ModalSendMail = (props:any) => {

    const [open, setOpen] = useState(false);
    const [btnDisabled , setBtnDisabled] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        
        let id:any = document.getElementById('modal_sendmail_'+props.row.id);
        id.style.display = 'none';
        setOpen(false);
    
    }


    const [labelRecords,setLabelRecords] = useState('Detalle de Pago ( 2 avisos )');    

    const [montoBruto, setMontoBruto] = useState("");
    const [montoRetencion, setMontoRetencion] = useState("");
    const [montoNeto, setMontoNeto] = useState("");

    const [inputs, setInputs] = useState(
            {para: '', cc: '',keys: '', 
             observacion: '' , recordscc: [],
             calce_id_original: props?.row?.calce_id_original , 
             doc_type: props?.row?.dte?.doc_type ,
             folio:  props?.row?.dte?.folio ,
             montobruto : props?.row?.dte?.monto_bruto,
             montoimpuesto: props?.row?.dte?.monto_impuesto,
             razonsocial: props?.row?.dte?.razon_social,
             rutprestador: props?.row?.dte?.rut_prestador,
             fechacontable: props?.row?.dte?.fecha_contable,
             detallepagos: '',
             montobrutoselect: '',
             montoretencionselect: "",
             montonetoselect: ""
            });

    const handleClickSendEmail = async () => {

        if(!validateEmail(inputs.para)) {
            
            Notification['error']({
				title: 'El correo electrónico no es válido.',
				placement: 'topStart',
			});

            return false;
        } 

        /* if (inputs.recordscc.length <= 0) {
            
            Notification['error']({
				title: 'Se necesita un destinatario como mínimo',
				placement: 'topStart',
			});

            return false;

        } */


        let id:any = document.getElementById('data-resumen_montobruto').dataset.resumen_montobruto;
       
        let id_retencion:any = document.getElementById('data-resumen_montoretencion').dataset.resumen_montoretencion;
        let id_neto:any = document.getElementById('data-resumen_montoneto').dataset.resumen_montoneto;

        
        inputs.montobrutoselect = String(id);
        inputs.montoretencionselect = String(id_retencion);
        inputs.montonetoselect =  String(id_neto);

        let id_hdd_label:any = document.getElementById('hdd_label_records');

        let records_label = id_hdd_label.value;

        if(records_label > 1) {
            inputs.detallepagos = "Detalle de Pago ( " + " " + String(records_label) + " avisos )";
        }  else {
            inputs.detallepagos = "Detalle de Pago ( " + " " + String(records_label) + " aviso )";
        }
        

        setBtnDisabled(true);

        let send:any = await SendMailDocumentosNoCalzados(inputs);
        
        if(send.status == 'success'){

            Notification['success']({
				title: 'El correo electrónico ha sido enviado correctamente.',
				placement: 'topStart',
			});
            
            setTimeout(() => { window.location.reload(); }, 2000);

            handleClose();

        } else {

            Notification['error']({
				title: 'No se pudo enviar el correo electrónico.',
				placement: 'topStart',
			});

        }

        setBtnDisabled(false);

        // Send Email and Save ...

    }


    const handleAddEmailCC = (e:any) => {

        if(!validateEmail(inputs.cc)) {
            
            Notification['error']({
				title: 'El correo electrónico no es válido.',
				placement: 'topStart',
			});
           
            return false;

        } else {
            if(Exist(inputs.cc)) {

                Notification['error']({
                    title: 'El correo electrónico ya está en la lista de destinatarios.',
                    placement: 'topStart',
                });
               
                return false;

            } else {

                let aux_records = inputs.recordscc;
                aux_records.push(inputs.cc);
                
                setInputs({...inputs, recordscc: aux_records});

            }
        }

    }

    const Exist = (email:any) => {
        
        return inputs.recordscc.includes(email) 
    }

    const handleClickOpen = async () => {

        let id:any = document.getElementById('data-resumen_montobruto').dataset.resumen_montobruto;
        let id_retencion:any = document.getElementById('data-resumen_montoretencion').dataset.resumen_montoretencion;
        let id_neto:any = document.getElementById('data-resumen_montoneto').dataset.resumen_montoneto;

        if( id <= 0) {
            Notification['error']({
				title: 'El Monto Bruto debe ser mayor a cero.',
				placement: 'topStart',
			});

            return false;

        } else {

            let hdd_rut_selected:any = document.getElementById('hdd_rut_selected');

            //let email:any = await getEMailProfesional(props.row.rut_prestador);

            let email:any = await getEMailProfesional(hdd_rut_selected.value);

            setMontoBruto(formatNumber(id));
            setMontoRetencion(formatNumber(id_retencion));
            setMontoNeto(formatNumber(id_neto));

            let hdd_label_records:any =  document.getElementById('hdd_label_records');
            let records = hdd_label_records.value;
            
            if(records > 1) {
                inputs.detallepagos = "Detalle de Pago ( " + " " + String(records) + " avisos )";
            }  else {
                inputs.detallepagos = "Detalle de Pago ( " + " " + String(records) + " aviso )";
            }

            inputs.para = email.email;

            handleOpen();

        }
    }

    const handleDeleteEmailCC = (email:any, e:any) => {

        let aux_records = inputs.recordscc;

        let  items = aux_records.filter(function(item) {
            return item !== email; 
        });

        setInputs({...inputs, recordscc: items});

        e.preventDefault();

    }

    return(<>
            <Button className="btn btn-primary btn-sm py-0 px-1"
                    type="button" 
                    onClick={handleClickOpen}> <i className="fa fa-envelope"/></Button>
        <Modal show={open} onHide={handleClose} centered keyboard={true} key={props?.row?.id}  id={`modal_sendmail_${props?.row.id}`}>
        <Modal.Body>
            <Form.Group controlId="formBasicEmail">
                <Form.Label style={{ color: 'rgb(53, 72, 95)'  , fontWeight: 'bold' }}>Para</Form.Label>
                <Form.Control type="email" placeholder="Correo electrónico" readOnly={true} value={inputs.para} 
                />
            </Form.Group>

            
            <Form.Group controlId="formBasicEmail2">
                <Form.Label style={{ color: 'rgb(53, 72, 95)' , fontWeight: 'bold' }} >CC</Form.Label>
                {
                    inputs.recordscc?.length >  0 ?
                        inputs.recordscc?.map((c) => {
                            return(<li key={'li_item_'+c}>{c} <a href="/#" onClick={(e) => {handleDeleteEmailCC(c,e)}} >Eliminar</a></li>)
                        }) : 
                        <></>
                }
                <div className="form-group row" style={{ margin: '0' }}>
                    <Form.Control type="email" placeholder="Correo electrónico" 
                        className="col-9" 
                        onChange={(e) => { setInputs({...inputs,cc: e.target.value})}}
                    />
                    <button type="submit" className="btn btn-default col-3" 
                            style={{ backgroundColor :  'rgb(53, 72, 95)' , color: '#FFF'}}
                            onClick={(e) => {handleAddEmailCC(e)}}
                            >Agregar</button>
                </div>
            </Form.Group>
            <Table striped bordered hover size="sm" >
                <thead style={{backgroundColor: '#35485f' , color: '#FFF'}}>
                    <tr >
                        <th colSpan={2} className="text-center">
                            { inputs.detallepagos }    
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Monto Bruto</td>
                        <td className="text-right">$ {montoBruto}</td>
                    </tr>
                    <tr>
                        <td>Retención</td>
                        <td className="text-right"> $ {montoRetencion}</td>
                    </tr>
                    <tr>
                        <td>Monto Neto</td>
                        <td className="text-right"> $ {montoNeto}</td>
                    </tr>
                </tbody>
            </Table>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label  style={{ color: 'rgb(53, 72, 95)'  , fontWeight: 'bold' }}>Observación</Form.Label>
                <Form.Control as="textarea" rows={3} 
                 onChange={(e) => { setInputs({...inputs,observacion: e.target.value})}}/>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClickSendEmail} disabled={btnDisabled} style={{backgroundColor: '#35485f' , color: '#FFF'}} >
            Confirmar
          </Button>
          <Button onClick={handleClose} className="btn-danger" >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>)
}

export default ModalSendMail;