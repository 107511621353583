import React, { useState, useRef } from 'react';
import { DatePicker, Button, Notification } from 'rsuite';
import * as Api from '../../api/end.points'
import Logs from './Logs'

const Bitacora = () => {
	const [inputs, setInputs] = useState({});
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [btnDisabled2, setBtnDisabled2] = useState(false);

	// ref
	const fromCron = useRef(null);

	const handleSubmit = async (e:any) =>{
		e.preventDefault();
		setBtnDisabled(true);
		const data:any = await Api.SetCron(inputs);
		if (data.session) {
		}
		// console.log(data)
		if (data.validations != null) {
			const validate = data.validations;
			// console.log('api',validate)
			Api.LumenForm.validate(fromCron.current, validate);
		}
		setBtnDisabled(false);
	}
	const BtnPayroll = async () =>{
		setBtnDisabled2(true);
		await Api.GeneratePayroll({});
		Notification['success']({
			title: 'Nomina generada!',
			placement: 'bottomEnd',
			//duration: 20000,
		});
		setBtnDisabled2(false);
	}
	const handleInput = (e:any) =>{
		// console.log(e)
		setInputs({
			...inputs,
			[e.target.name]: e.target.value
		});
	}

	const descargar = async () => {
		const response:any = await Api.FindBhes({});
		if (Array.isArray(response)) {
			for (let i = 0; i < response.length; ++i) {
				alert(response[i].message)
			}
		}else{
			alert('hubo un error')
		}
	}
	return(
		<div className="row">
			<div className="col-12">
				<h5 className="py-2">
					Bitacora de procesos
					<hr className="my-2"/>
				</h5>
			</div>
			{/* <div className="col-4">
					<form className="form-inline" ref={fromCron} onSubmit={handleSubmit}>
					  <div className="form-group mb-2">
						    <label className="mr-2">CRON</label>
						    <DatePicker name="hour" format="HH:mm" ranges={[]} onChange={(date) => {
						    	const getHours = date.getHours();
						    	const getMinutes = date.getMinutes();
						    	handleInput({
										target:{
											name:'hour',
											value:`${getHours}:${getMinutes}`
										}
									});
						    }}/>
					  </div>
					  <Button appearance="link" type="submit" disabled={btnDisabled} >Actualizar</Button>
					  <Button type="button" disabled={btnDisabled} onClick={()=>BtnPayroll()}>Generar nomina</Button>
					</form>
			</div> */}
			{/* <div className="col-4">
			    <label className="mr-2">Boletas & Facturas</label>
			    <Button appearance="primary" onClick={descargar}>Descargar</Button>
		  </div> */}
		  <div className="col-12">
		  <Logs/>
		  </div>
		</div>
	);
}
export default Bitacora;