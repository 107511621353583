import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import moment from 'moment'; 
//import { useSelector, useDispatch } from 'react-redux'
// import PDFViewer from 'pdf-viewer-reactjs'

//import  actions from '../../redux/actions'

// components
import SimpleTable from '../../components/table/SimpleTable';
import SimpleModal from '../../components/modals/SimpleModal';
import RsuitePaginationLumen from '../../components/Paginations/RsuitePaginationLumen';
import Calculator from './calculator/Calculator';
import ModalSendMail from './calculator/ModalSendMail';
import ModalTramaReference from './calculator/ModalTramaReference';

import { connect } from 'react-redux';
import { Modal, ButtonToolbar , Button } from 'react-bootstrap';
// services
import { Amount, DigitoVerificador } from '../../services/Utils';

// partials
import InvoiceDetails from './InvoiceDetails';
import InvoicesFiltres from './InvoicesFiltres';

import * as Api from '../../api/end.points'
import { ExportCSVFile } from '../../services/Utils';


import ModalBajaManual from './calculator/ModalBajaManual';

const ChangeIcon = (e:any) => {
	const btn = e.currentTarget;
	const icon1 = btn.firstElementChild.classList;
	
	if(btn.getAttribute("aria-expanded")==="true"){
		icon1.remove('fa-plus');
		icon1.add('fa-minus');
	}else{
		icon1.remove('fa-minus');
		icon1.add('fa-plus');
	}
}

const InvoiceListNotAmount = ( props:any ) => {
	const history = useHistory();
	let queryUri:any = QueryString.parse(history.location.search);
	const [loadingMail, setLoadingMail] = useState({
		state:false,
		id:null,
		response:null
	});
	const [list, setlist] = useState({results:null});
	const [listLoading, setlistLoading] = useState(true);
	const [CsvLoading, setCsvLoading] = useState(false);

	const [seletcDocument, setSeletcDocument] = useState(0);

	const showFile = async(row) => {
		setSeletcDocument(0)
		// console.log('row',row)
		const a:any = await Api.Bhes(DigitoVerificador(row.rut_prestador),{folio:row.dte.folio});
		// console.log(a);
		setSeletcDocument(a)
	}

	const sendMail = async(row) => {
		setLoadingMail({
			...loadingMail,
			state:true,
			id:row.id
		});
		// hostorial para indicar al back que id buscar
		let data = {
			...row,
			historial:true
		};
		if (Object.keys(queryUri).length === 0 ) {
			data={
				...data,
				historial:false
			}
		}
		const response:any = await Api.SendMail(data);
		alert('Mensaje enviado')
		setLoadingMail({
			state:false,
			id:null,
			response:response
		});
	}

	const GenerateCsv = async () => {

		setCsvLoading(true);
		let query:any = QueryString.parse(history.location.search);
		const getData:any = await Api.ShimAll(query);

		const headers = {
			id: '#',
			rut_prestador: 'Rut Prestador',
			fecha_documento: 'Fecha de Documento',
			fecha_proceso: 'Fecha de Proceso',
			numero_documento: 'Numero Documento',
			razon_social: 'Razon Social',
			neto_impuesto: 'Neto Impuesto',
			impuesto: 'Impuesto',
			total: 'Total Honorarios',
			tipobaja: 'Tipo Baja',
			motivobaja: 'Motivo Baja',
			fechabaja: 'Fecha Baja',
		};

		const data = [];
	   
		for (let i = 0; i < getData.length; ++i) {
			let row = getData[i];
			let fecha  = moment(row.created_at).local().format('DD/MM/YYYY');

				data.push({
					id: row.id,
					rut_prestador: row.rut_prestador,
					fecha_documento: row.fecha_contable,
					fecha_proceso: fecha,
					numero_documento: row.folio + ' ' + row.doc_type,
					razon_social: row.razon_social,
					neto_impuesto: row.monto_bruto,
					impuesto: row.monto_impuesto == null ? 0 : row.monto_impuesto,
					total: ( row.monto_impuesto  == null ? 0 : row.monto_impuesto )  + ( row.monto_bruto == null ? 0 : row.monto_bruto ) ,
					tipobaja: row.tipo_baja,
					motivobaja: row.motivo_baja,
					fechabaja: row.fecha_baja == null ? '' :  row.tipobaja_id == 1 ? "" : row.fecha_baja,
				})
		}

	   	ExportCSVFile(headers,data,'DOCUMENTOS_NO_CALZADOS');
		setCsvLoading(false);

	}

	useEffect(()=>{
		const runList = async () => {
			setlistLoading(true);
			setlist({
				...list,
				results:null
			})
			let query:any = QueryString.parse(history.location.search);
			const getData:any = await Api.Shim1(query);
			setlistLoading(false);
			setlist({...getData})
		}

		runList();
		
	},[props,history]);
	if(list.results!=null){
		for (var i = 0; i < list.results.length; ++i) {
		
	 		list.results[i]['trExtra'] = (row:any) => {
	 			const aux_row = {...row};
	 			return(
	 				<InvoiceDetails {...aux_row} showFile={showFile}  checkbox={true} disabled/>
	 			)
	 		}
	 	}
	}
	return(
		<React.Fragment>
			<h5 className="pt-2">
				Documentos no calzados
				{ 
					Object.keys(queryUri).length === 0 ? 
					<small className="text-success"> Ultimos resultados</small> : 
						<small className="text-primary"> Historial</small>
				}
				<hr className="my-2"/>
			</h5>
			<strong>Nota:</strong><small>no han sido exitosos porque el monto no coincide</small>
			<InvoicesFiltres viewadvancedfilter={1} data={list.results} GenerateCsv={GenerateCsv} CsvLoading={CsvLoading} />
			<Calculator />
			<div id="div_table_not_amount">
				<SimpleTable
					messageEmpty={'No hay documentos con diferencia de montos con las ordenes'}
					data={list.results}
					onLoadingTable={listLoading}
					trHeader={[
						{thClassName:'samll px-2 pb-0 text-white',content:'#'},
						{thClassName:'samll px-2 pb-0 text-white',content:'RUT Prestador'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Fecha de Documento'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Fecha de Proceso'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Número Documento'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Razón Social'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Neto de impuesto'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Impuesto'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Total honorarios'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Motivo de no calce'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Correos'},
						{thClassName:'samll px-2 pb-0 text-white',content:'OPCIONES'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Tipo Baja'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Motivo Baja'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Fecha Baja'},
					]}
					trBody={[
						{tdClassName:'small px-2 py-0 pt-1', attrData:'id'},
						{
							tdClassName:'small px-2 py-0 pt-1',
							attrData:'rut_prestador',
							content: (item,row) =>{
									return(
										<label>
											{DigitoVerificador(row.rut_prestador)}
										</label>
									)
							}
						},
						{
							tdClassName:'small px-2 py-0 pt-1', attrData:'dte.fecha_contable',
							content: (item,row) =>{
								return(
									<label>
										{moment(item).local().format('DD/MM/YYYY')}
									</label>
								)
							}
						},
						{
							tdClassName:'small px-2 py-0 pt-1',
							attrData:'created_at',
							content: (item, row) =>{
									return(
										<label>
												{moment(item).local().format('DD/MM/YYYY HH:mm:ss')}
										</label>
									)
							}
						},
						{
							tdClassName:'small px-2 py-0 pt-1',
							attrData:'dte',
							content: (item,row) =>{
									return(
										<label className="d-block">
											<a href="#MontoNoCalzadoBoleta" data-toggle="modal" data-target="#MontoNoCalzadoBoleta" onClick={(e) => showFile(row)}>
												{item?.folio}
											</a>
											<small className="float-right">{row.dte?.doc_type}</small>
										</label>
									)
							}
						},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'dte.razon_social'},
						{
							tdClassName:'small px-2 py-0 pt-1',
							attrData:'dte.monto_bruto',
							content: (item,row) =>{
									return(
										<label>
											{Amount(item)}
										</label>
									)
							}
						},
						{
							tdClassName:'small px-2 py-0 pt-1',
							attrData:'dte.monto_impuesto',
							content: (item,row) =>{
									return(
										<label>
											{Amount(item)}
										</label>
									)
							}
						},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'dte', content:(item,row)=>{
							return(
								<React.Fragment>
									{Amount( (item?.monto_impuesto == null ? 0 : item?.monto_impuesto) + (item?.monto_bruto == null ? 0 : item?.monto_bruto))}
								</React.Fragment>
							);
						}},
						{
							tdClassName:'small px-2 py-0 pt-1',
							attrData:'motivo_no_calce',
							content: (item,row) =>{
								return(
									<label>
										{row?.detalle?.motivo_no_calce}
									</label>
								)
							}
						},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'count_mails'},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'options', content:(item,row)=>{
							return(
								<React.Fragment>
									<button
										className="btn btn-primary btn-sm py-0 px-1"
										type="button"
										data-toggle="collapse"
										data-target={`#details-invoce-${row?.id}`}
										aria-expanded={false}
										aria-controls={`details-invoce-${row?.id}`}
										onClick = {ChangeIcon}
									>
										<i className="fa fa-plus"/>
									</button>{' '}
									<ModalSendMail row={row}></ModalSendMail>
									<ModalBajaManual row={row}></ModalBajaManual>
									<ModalTramaReference row={row}></ModalTramaReference>

								</React.Fragment>
							);
						}},

						{
							tdClassName:'small px-2 py-0 pt-1 text-center',
							attrData:'tipobaja.description',
							content: (item,row) =>{
								return(
									<label>
										{item}
									</label>
								)
							}
						},
						{
							tdClassName:'small px-2 py-0 pt-1',
							attrData:'motivobaja.description',
							content: (item,row) =>{
								return(
									<label>
										{item}
									</label>
								)
							}
						},
						{
							tdClassName:'small px-2 py-0 pt-1',
							attrData:'fecha_baja',
							content: (item,row) =>{
								return(
									<label>
										{row?.tipobaja?.id == 1  ? "" : row?.fecha_baja }
									</label>
								)
							}
						},
					]}
				/>
			</div>
			<RsuitePaginationLumen {...list}/>
			<SimpleModal title="Documento electronico" btnCloseText="Cerrar" idModal="MontoNoCalzadoBoleta">
					{seletcDocument===0 
						? 'Buscando..' 
							: seletcDocument[0].results.length === 0 ? 'Archivo no encontrado en el bot' : <a  className="btn btn-success" href={seletcDocument[0].results[0].document_file}>descargar</a>}
			</SimpleModal >
		</React.Fragment>
	);
}


const mapStateToProps = (state: any) => ({
    calculator: state.calculator
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListNotAmount)
