import React, { useState, useEffect } from 'react';
import * as QueryString from 'query-string';
import { withRouter,useHistory } from 'react-router-dom';
import { Pagination } from 'rsuite';

const RsuitePaginationLumen = (props:any) => {
	const history = useHistory();
	const query:any = QueryString.parse(history.location.search);
	const [activePage,setActivePage]=useState(1);

	const handleSelect = (eventKey:number) => {
		query['page'] = eventKey;
		history.push({
			pathname: history.location.pathname,
			search:QueryString.stringify(query),
			state: { page: eventKey }
		})
		setActivePage(eventKey);
	}
	
	useEffect(()=>{
		if (activePage!==query.page) {
			let pageCurrent = parseInt(query.page)
			setActivePage( isNaN(pageCurrent) ? 1 : pageCurrent)
		}
	},[setActivePage,activePage,query]);

	return (
		<Pagination
			prev last next first
			size="lg"
			ellipsis={true}
			boundaryLinks={true}
			maxButtons={5}
			pages={props.last_page}
			activePage={activePage}
			onSelect={handleSelect}
		/>
	);
}

export default withRouter(RsuitePaginationLumen);