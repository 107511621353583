import { useHistory } from "react-router-dom";

export const MenuOne = [
	/*
	{
		title:"INTRANET AMSM",
		to:"modulo-intranet",
		addClass:"btn-intranet",
		"subItems":[
			{
				title:"PAM ORIGINAL",
				to:"/",
			},
			{
				title:"SMS",
				to:"/",
			},
			{
				title:"Mail",
				to:"/",
			},
			{
				title:"Template",
				to:"/",
			},
			{
				title:"Listas de Distribución",
				to:"/",
			},
			{
				title:"Informes SMS",
				to:"/",
			},
			{
				title:"Informes Mail",
				to:"/",
			},
			{
				title:"Usuarios",
				to:"/",
			},
			{
				title:"Perfil",
				to:"/",
			},
			{
				title:"Intranet/Médicos",
				to:"/",
			},
			{
				title:"Mantenedor de Contenidos",
				to:"/",
			},
			{
				title:"Mantenedor Sociedades",
				to:"/",
			},
			{
				title:"Estadistica Usabilidad",
				to:"/",
			},
			{
				title:"Avisos y Encuestas",
				to:"/",
			},
			{
				title:"Consulta de Programa",
				to:"/",
			},
			{
				title:"Gestión Masiva de Cuentas",
				to:"/",
			},
			{
				title:"Procesos Satélites",
				to:"/",
			},
			{
				title:"Mantenedor de Profesionales",
				to:"/",
			},
			{
				title:"Cuadro de Resumen Por Profesional",
				to:"/",
			},
			{
				title:"Ejecucion de procesos",
				to:"/",
			},
			{
				title:"Ingreso Flujo PAM",
				to:"/",
			},
			{
				title:"Modificacion PAM",
				to:"/",
			},
			{
				title:"Recaudacion",
				to:"/",
			},
			{
				title:"Anulados",
				to:"/",
			},
			{
				title:"Cambio de estado PAM enviados a Isapre",
				to:"/",
			},
			{
				title:"Detalle PAM Original",
				to:"/",
			},
			{
				title:"Consulta Aranceles",
				to:"/",
			},
			{
				title:"Cambio de tipo PAM masivo",
				to:"/",
			},
			{
				title:"Eliminación de Recaudaciones",
				to:"/",
			},
			{
				title:"Visación PAM",
				to:"/",
			},
			{
				title:"Mantención PAM",
				to:"/",
			},
			{
				title:"PAM enviados a cuentas",
				to:"/",
			},
			{
				title:"Buzón Electrónico PAM - RRMM",
				to:"/",
			},
			{
				title:"Buzón Electrónico PAM - Operaciones",
				to:"/",
			},
			{
				title:"Buzón PAM",
				to:"/",
			},
			{
				title:"Ver Imagenes PAM",
				to:"/",
			},
		],
	},
	{
		title:"MÓDULO TRANFERENCIA",
		to:"modulo-tranferencia",
		addClass:"btn-tranferencia",
		"subItems":[
			{
				title:"Ingresar Boletas",
				to:"/",
			},
			{
				title:"Reimprimir Detalle de Pago",
				to:"/",
			},
			{
				title:"Envío Nómina",
				to:"/",
			},
			{
				title:"Reimprimir Nómina RRMM",
				to:"/",
			},
			{
				title:"Modificar Número de Boleta",
				to:"/",
			},
			{
				title:"Cartola Profesional",
				to:"/",
			},
			{
				title:"Nóminas Recibidas",
				to:"/",
			},
			{
				title:"Reimprimir Nómina Revisada",
				to:"/",
			},
			{
				title:"Transferir Nóminas",
				to:"/",
			},
			{
				title:"Reimprimir Nómina Transferida",
				to:"/",
			},
			{
				title:"Documentos Rechazados",
				to:"/",
			},
			{
				title:"Rechazos Transferencias",
				to:"/",
			},
			{
				title:"Nómina Pago Recibido",
				to:"/",
			},
			{
				title:"Mantenedor Bancario",
				to:"/",
			},
			{
				title:"Cambiar Prestador",
				to:"/",
			},
		],
	},
	{
		title:"MÓDULO CONTABILIDAD",
		to:"modulo-contabilidad",
		addClass:"btn-contabilidad",
		"subItems":[
			{
				title:"Cargos y Abonos",
				to:"Ejemplo",
			},
			{
				title:"Ingresar Boleta/Honorario",
				to:"/",
			},
			{
				title:"Honorarios AMSM",
				to:"/",
			},
			{
				title:"Libro de Retenciones",
				to:"/",
			},
			{
				title:"Descarga CSV",
				to:"/",
			},
		],
	},*/
	{
		title:"DTE",
		to:"modulo-bhes",
		addClass:"btn-bhes",
		"subItems":[
			{
				title:"Doc. Calzados",
				to:"/invoices/calce",
			},
			{
				title:"Doc. Sin calces en montos",
				to:"/invoices/error/montos?page=1&tipobaja=1",
			},
			// {
			// 	title:"Doc. Sin ordenes de pago",
			// 	to:"/invoices/error/sin/ordenes",
			// },
			,
			{
				title:"Notas de Credito",
				to:"/notas-credito",
			},
			{
				title:"Anulaciones",
				to:"/anulaciones-bhe",
			},
			{
				title:"Bitacora de procesos",
				to:"/bitacora",
			},
			{
				title:"Configuración",
				to:"/config"
			},
		],
	}
];