import { types } from '../types';

export const INITIAL_STATE = {
	list: {
		results:null,
		data:null
	},
	listLoading:true
};

const InvoiceReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.INVOICE_LIST:
			return {
				...state,
				list:action.payload.list,
				listLoading:action.payload.listLoading
			 };
		default: return state;
	}
};

export default InvoiceReducer;
