import React from 'react';
import PropTypes from 'prop-types';

const SimpleLoading = (props:any) => {
		const {
			loading, message, children
		} = props;
		if (loading === true) {
			return(
				<div className="text-center col py-5">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
					<h5>{message}</h5>
				</div>
			)
		}
		return(
			<div className="row">{children}</div>
		);
}

SimpleLoading.propTypes = {
		loading:PropTypes.bool.isRequired,
		message:PropTypes.string.isRequired,
		children:PropTypes.any,
};

SimpleLoading.defaultProps = {
		message: 'Un momento por favor...',
		loading: true,
		children:{},
};

export default SimpleLoading;