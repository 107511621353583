import React from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';

import Imgs from '../components/media/Imgs';
import NavbCollapse from '../components/nav/NavCollapse';
import {MenuOne} from './MenuAdmin';

import  Actions from '../redux/actions';

// import * as Api from '../api/end.points'
const LayoutAdmin = ( props:any ) => {
	const dispatch = useDispatch();
	return(
		<React.Fragment>
			<nav className="nav-top-1 nav nav-pills nav-justified py-3 px-5bg-white">
				<div className="container-fluid px-5">
					<div className="row w-100">
						<div className="col-md-6">
							<Link to="/">
								<img src={Imgs.LogoAmsmGris} alt="LogoAmsmGris" className="logo-gris"/>
							</Link>
						</div>
						<div className="col-md-6 align-self-star text-right">
							<div className="btn-group">
								<a href="https://intranet.amsm.cl/" className="btn btn-light">Intranet</a>
								<button
									type="button"
									className="btn btn-light"
									onClick={()=>{
										dispatch(Actions.Auth.logout())
									}}
								>
									Cerrar Sesión
								</button>
							</div>
						</div>
					</div>
				</div>
			</nav>
			<NavbCollapse items={MenuOne}/>
			<div className="container-app container-fluid px-5 pt-3 bg-white">
				{/*<button type="button" className="btn btn-primary" onClick={addCart}>test</button>*/}
			{props.children}
			</div>
		</React.Fragment>
	);
}
export default  LayoutAdmin;