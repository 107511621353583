import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Imgs from '../../components/media/Imgs';
import { Notification } from 'rsuite';
import Inputmask from "inputmask";

import  Actions from '../../redux/actions';
import  * as Api from '../../api/end.points';
import { OpenSession } from '../../api/session';
/*document.addEventListener("DOMContentLoaded", function(event) {
	try {
		var selector = document.getElementById("rut-login");
		var im = new Inputmask("(9(.999){2}-K)|(99(.999){2}-K)");
		im.mask(selector);
	} catch (error) {
	  console.error('Error en MainNav');
	  // expected output: ReferenceError: nonExistentFunction is not defined
	  // Note - error messages will vary depending on browser
	}
});*/

const SingIn = () => {

	const dispatch = useDispatch();
	// const { chekout } = useSelector( (state : any ) => state );

	// state
	const [inputs, setInputs] = useState({rut:'',password:''});
	const [btnDisabled, setBtnDisabled] = useState(false);

	// ref
	const fromSingIn = useRef(null);

	const handleSubmit = async (e:any) =>{
		e.preventDefault();
		setBtnDisabled(true);
		const data:any = await Api.SingIn(inputs);
		if (data.session) {
			OpenSession(data.session);
			Notification['success']({
				title: 'Bienvenido!',
				placement: 'bottomEnd',
				//duration: 20000,
			});
			dispatch(Actions.Auth.login());
			//const modal=document.getElementById('auth-modal-in-layout')
			//bootstrap.Modal.getInstance(modal).hide()

		}
		
		if (data.validations != null) {
			const validate = data.validations;
			if (validate.errors) {
				Notification['error']({
			    title: validate.errors[0],
			    placement: 'bottomEnd',
			    //duration: 20000,
			  });
			}else{
				Api.LumenForm.validate(fromSingIn.current, validate);
			}
		}
		setBtnDisabled(false);
	}
	const handleInput = (e:any) =>{
		Api.LumenForm.validate(fromSingIn.current, []);
		if(e.target.name==='rut'){
			var rule = e.target.value.length > 12 ? '9[9.999.999]-[9|K|k]' : '[9.999.999]-[9|K|k][99]';
			var selector = document.getElementById("rut");
			var im = new Inputmask(rule);
			im.mask(selector);
		}
		setInputs({
			...inputs,
			[e.target.name]: e.target.value
		});
	}
	return(
		<div className="row justify-content-center pb-3 align-items-center" style={{minHeight:"100vh"}}>
			<div className="col-8 col-md-6 col-lg-4 col-xl-3">
				<div className="row justify-content-lg-center">
					<div className="col-12 text-center mb-5">
						<img className="img-fluid" src={Imgs.LogoColors} alt="AMSM"/>
					</div>
					<form className="col-lg-12 bg-white py-3 rounded"  onSubmit={handleSubmit} ref={fromSingIn}>
						<div className="mb-3">
							<label className="form-label">Rut</label>
							<input
								id="rut"
								name="rut"
								type="rut"
								className="form-control"
								onChange={handleInput}
								placeholder="Rut"
								value={inputs.rut}
							/>
						</div>
						<div className="mb-3">
							<label className="form-label">Contraseña</label>
							<input
								name="password"
								type="password"
								className="form-control"
								onChange={handleInput}
								placeholder="Contraseña"
							/>
						</div>
						{/*<div className="mb-3 form-check">
							<input type="checkbox" className="form-check-input" id="exampleCheck1"/>
							<label className="form-check-label">Recuerdame</label>
						</div>*/}
						<button type="submit" className="btn btn-primary w-100" disabled={btnDisabled}>Ingresar</button>
					</form>
				</div>
			</div>
		</div>
	);
}
export default SingIn;