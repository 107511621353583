import React from 'react';
const SimpleCollapse = (props:any) => {
	const addClass= props.addClass ? props.addClass : '';
	const idParent= props.idParent ? props.idParent : '';
	const addAttr= {
		'data-parent':idParent
	}
	return(
		<div className={`collapse ${addClass}`} id={`${props.idCollapse}`} {...addAttr}>
			{props.children}
		</div>
	);
};

export default SimpleCollapse;