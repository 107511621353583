import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import moment from 'moment';
import SimpleTable from '../../components/table/SimpleTable';
import RsuitePaginationLumen from '../../components/Paginations/RsuitePaginationLumen';
import * as Api from '../../api/end.points';
import { Amount, DigitoVerificador, ExportCSVFile } from '../../services/Utils';

import InvoiceNCFilters from './InvoiceNCFilters';

const NotasCredito = ( props:any ) => {
    const history = useHistory();
	//// console.log('VISTA LIST',list.results)
	const [list, setlist] = useState({results:null});
	const [listLoading, setlistLoading] = useState(true);
	const [seletcDocument, setSeletcDocument] = useState(0);
	let queryUri:any = QueryString.parse(history.location.search);
	const [CsvLoading, setCsvLoading] = useState(false);

	const GenerateCsv = async () => {

		setCsvLoading(true);
		let query:any = QueryString.parse(history.location.search);

		const rows:any = list.results.length < 15 ? list.results : await Api.getAllNC(query);

		const headers = {
			id : '#',
			provider_rut : 'RUT Prestador',
			social_reason : 'Razón Social',
			recieved_rut : 'RUT Receptor',
			reception_date : 'Fecha Recepción',
			created_at : 'Fecha Creación',
			status : 'Estado',
			total_amount : 'Monto Total',
			folio : 'Folio',
			reference_folio : 'Ref. Folio'			
		};
		
		const data = [];

		for (let i = 0; i < rows.length; ++i) {
			let row = rows[i];
			data.push({
				id: row.id,
				provider_rut: row.provider_rut,
				social_reason: row.social_reason,
				recieved_rut: row.recieved_rut,
				reception_date: row.reception_date,
				created_at: row.created_at,
				status: row.status,
				total_amount: row.total_amount,
				folio: row.folio,
				reference_folio: row.reference_folio
			});
		}

	   	ExportCSVFile(headers,data,'NOTAS_CREDITO');

		setCsvLoading(false);

	}

    useEffect(()=>{
		const runList = async () => {
			setlistLoading(true);
			setlist({
				...list,
				results:null
			})
			let query:any = QueryString.parse(history.location.search);
			const getData:any = await Api.getNC(query);
			setlist({...getData})
			setlistLoading(false);
		}
		runList();
	},[props,history]);

    return(<>
       <React.Fragment>
			<h5 className="py-2">
				Listado Notas de Credito
				<hr className="my-2"/>
			</h5>
            <InvoiceNCFilters GenerateCsv={GenerateCsv} CsvLoading={CsvLoading} />
			<SimpleTable
				messageEmpty={'No hay documentos calzados'}
				data={list.results}
				onLoadingTable={listLoading}
				trHeader={[
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'#'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'RUT Prestador'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Razón Social'},
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'RUT Receptor'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Fecha Recepción'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Fecha Creación'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Estado'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Monto Total'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Folio'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Ref. Folio'},
				]}
				trBody={[
					{tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'id'},
					{tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'provider_rut'},
                    {tdClassName:'small px-2 py-0 pt-1', attrData:'social_reason'},
                    {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'recieved_rut'},
					{
						tdClassName:'small px-2 py-0 pt-1 text-center',
						attrData:'reception_date',
						content: (item,row) =>{
							return(
								<label>
									{moment(item).local().format('DD/MM/YYYY')}
								</label>
							)
						}
					},
                    {
						tdClassName:'small px-2 py-0 pt-1 text-center',
						attrData:'created_at',
						content: (item,row) =>{
							return(
								<label>
									{moment(item).local().format('DD/MM/YYYY')}
								</label>
							)
						}
					},
					{tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'status'},
					{tdClassName:'small px-2 py-0 pt-1 text-right', attrData:'total_amount', content:(item)=>Amount(item)},
					{tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'folio'},
					{tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'reference_folio'},
				]}
			/>
			<RsuitePaginationLumen {...list}/>
		</React.Fragment>
    </>)

} 

export default NotasCredito;