import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import moment from 'moment'; 
//import { useSelector, useDispatch } from 'react-redux'

//import  actions from '../../redux/actions'

// components
import SimpleTable from '../../components/table/SimpleTable';
import SimpleModal from '../../components/modals/SimpleModal';
import RsuitePaginationLumen from '../../components/Paginations/RsuitePaginationLumen';


// services
import { Amount, DigitoVerificador, ExportCSVFile } from '../../services/Utils';

// partials
import InvoiceDetails from './InvoiceDetails';
import InvoicesFiltres from './InvoicesFiltres';

import * as Api from '../../api/end.points';

const ChangeIcon = (e:any) => {
	const btn = e.currentTarget;
	const icon1 = btn.firstElementChild.classList;
	console.log(btn)
	
	if(btn.getAttribute("aria-expanded")==="true"){
		icon1.remove('fa-plus');
		icon1.add('fa-minus');
	}else{
		icon1.remove('fa-minus');
		icon1.add('fa-plus');
	}
}

const InvoiceListCalce = ( props:any ) => {
	const history = useHistory();
	// const { list, listLoading } = useSelector( (state) => state.invoice )
	// const dispatch = useDispatch()
	// const { InvoiceAction } = actions;

 //  if(list.results==null){
	// 		InvoiceAction.setList(dispatch)
	// }else{
	// 	for (var i = 0; i < list.results.length; ++i) {
	// 		list.results[i]['trExtra'] = (row:any) => { return(<InvoiceDetails {...row}/>) }
	// 	}
	// }
	//// console.log('VISTA LIST',list.results)
	const [list, setlist] = useState({results:null});
	const [listLoading, setlistLoading] = useState(true);
	const [seletcDocument, setSeletcDocument] = useState(0);
	let queryUri:any = QueryString.parse(history.location.search);
	// console.log('queryUri',queryUri)
	const showFile = async(row) => {
		setSeletcDocument(0)
		let a:any = null
		if(row.dte.doc_type ==='Factura de compra'){
			a = await Api.PurchaseInvoices(DigitoVerificador(row.rut_prestador),{folio:row.dte.folio});
		}else{
			a = await Api.Bhes(DigitoVerificador(row.rut_prestador),{folio:row.dte.folio});
		}
		// console.log(a);
		setSeletcDocument(a)
	} 

	const loadList = async () => {
		const getData:any = await Api.Shim0({});
		setlistLoading(false);
		setlist(getData)
	}
	// console.log(list)
	if(list.results!=null){
		for (var i = 0; i < list.results.length; ++i) {
	 		list.results[i]['trExtra'] = (row:any) => { return(<InvoiceDetails {...row}/>) }
	 	}
	}
	useEffect(()=>{
		const runList = async () => {
			setlistLoading(true);
			setlist({
				...list,
				results:null
			})
			let query:any = QueryString.parse(history.location.search);
			const getData:any = await Api.Shim0(query);
			setlist({...getData})
			setlistLoading(false);
		}
		runList();
	},[props,history]);
	return(
		<React.Fragment>
			<h5 className="py-2">
				Documentos calzados 
				{ 
					Object.keys(queryUri).length === 0 ? 
					<small className="text-success"> Ultimos resultados</small> : 
						<small className="text-primary"> Historial</small>
				}
				<hr className="my-2"/>
			</h5>
			<InvoicesFiltres calces={list.results}/>
			<SimpleTable
				messageEmpty={'No hay documentos calzados'}
				data={list.results}
				onLoadingTable={listLoading}
				trHeader={[
					{thClassName:'samll px-2 pb-0 text-white',content:'#'},
					{thClassName:'samll px-2 pb-0 text-white',content:'RUT Prestador'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Fecha de Documento'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Número Documento'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Razón Social'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Fecha de Proceso'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Neto de impuesto'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Impuesto'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Total honorarios'},
					{thClassName:'samll px-2 pb-0 text-white',content:'OPCIONES'},
				]}
				trBody={[
					{tdClassName:'small px-2 py-0 pt-1', attrData:'id'},
					{
						tdClassName:'small px-2 py-0 pt-1',
						attrData:'rut_prestador',
						content: (item,row) =>{
								return(
									<label>
										{DigitoVerificador(row.rut_prestador)}
									</label>
								)
						}
					},
					{
						tdClassName:'small px-2 py-0 pt-1',
						attrData:'dte.fecha_contable',
						content: (item,row) =>{
							return(
								<label>
									{moment(item).local().format('DD/MM/YYYY')}
								</label>
							)
						}
					},
					{
						tdClassName:'small px-2 py-0 pt-1',
						attrData:'dte',
						content: (item,row) =>{
								return(
									<label className="d-block">
										<a href="#CalceBoleta" data-toggle="modal" data-target="#CalceBoleta" onClick={(e) => showFile(row)}>
											{item.folio}
										</a>
										<small className="float-right">{row.dte.doc_type}</small>
									</label>
								)
						}
					},
					{tdClassName:'small px-2 py-0 pt-1', attrData:'dte.razon_social'},
					{
						tdClassName:'small px-2 py-0 pt-1',
						attrData:'created_at',
						content: (item,row) =>{
								return(
									<label>
										{moment(item).local().format('DD/MM/YYYY HH:mm:ss')}
									</label>
								)
						}
					},
					{tdClassName:'small px-2 py-0 pt-1', attrData:'dte.monto_bruto', content:(item)=>Amount(item)},
					{tdClassName:'small px-2 py-0 pt-1', attrData:'dte.monto_impuesto', content:(item)=>Amount(item)},
					{tdClassName:'small px-2 py-0 pt-1', attrData:'dte', content:(item,row)=>{
						return(
							<React.Fragment>
								{Amount(item.monto_impuesto+item.monto_bruto)}
							</React.Fragment>
						);
					}},
					{tdClassName:'small px-2 py-0 pt-1', attrData:'options', content:(item,row)=>{
						return(
							<React.Fragment>
								<button
									className="btn btn-primary btn-sm py-0 px-1"
									type="button"
									data-toggle="collapse"
									data-target={`#details-invoce-${row.id}`}
									aria-expanded={false}
									aria-controls={`details-invoce-${row.id}`}
									onClick = {ChangeIcon}
								>
									<i className="fa fa-plus"/>
								</button>
							</React.Fragment>
						);
					}},
				]}
			/>
			<RsuitePaginationLumen {...list}/>
			<SimpleModal title="Documento electronico" btnCloseText="Cerrar" idModal="CalceBoleta">
					{seletcDocument==0 
						? 'Buscando..' 
							: seletcDocument[0].results.length === 0 
								? 'Archivo no encontrado en el bot' 
									: <a  className="btn btn-success" href={seletcDocument[0].results[0].document_file}>descargar</a>
					}
			</SimpleModal >
		</React.Fragment>
	);
}
export default InvoiceListCalce;