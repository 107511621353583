import React from 'react';

const SimpleModal = (props:any) => {
	return(
		<div className="modal fade" id={props.idModal} tab-index="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div className="modal-dialog modal-xl" role="document">
		    <div className="modal-content">
		      <div className="modal-header">
		        <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
		        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div className="modal-body">
		        {props.children}
		      </div>
		      <div className="modal-footer">
		        <button type="button" className="btn btn-secondary" data-dismiss="modal">{props.btnCloseText}</button>
		      </div>
		    </div>
		  </div>
		</div>
	)
}
export default SimpleModal;