import { types } from '../types';
import { INITIAL_STATE } from '../reducers/calculatorReducer';


export const Add =  (montobruto:any, montoretencion:any, montoneto:any , montodescuentos:any, montopagar:any) => async (dispatch: any)  =>{
             
    const action = {
		type: types.MONTO_ADD,
		payload:{
			montobruto: montobruto, 
            montoretencion: montoretencion, 
            montoneto: montoneto, 
            montodescuentos: montodescuentos, 
            montopagar: montopagar,
            reload: false
		}
	}
    dispatch(action);

}

export const Remove = (montobruto:any, montoretencion:any, montoneto:any , montodescuentos:any, montopagar:any)  => async (dispatch: any) => {

    const action = {
		type: types.MONTO_REMOVE,
		payload:{
                montobruto: montobruto, 
                montoretencion: montoretencion, 
                montoneto: montoneto, 
                montodescuentos: montodescuentos, 
                montopagar: montopagar,
                reload: false
            }
	}

    dispatch(action);

}

export const Reload = (value:any) => async (dispatch:any) => {

    

    const action = {
		type: types.CALCULATOR_RELOAD,
		payload:{
                reload: value
            }
	}

    dispatch(action);

}