import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import SimpleCollapse from '../list.all/SimpleCollapse';


const NavbCollapse = (props:any) => {
		const {
			items
		} = props;
		
		return(
			<div className="accordion" id="accordionNav">
				<nav className="nav-top-2 nav nav-pills nav-justified">
					{
						items.map((item,index)=>{
							const classItem= item.addClass ? item.addClass : '';
							return(
								<button
									className={`nav-item nav-link rounded-0 btn btn-primary py-2 ${classItem}`}
									data-toggle="collapse"
									data-target={`#nav-collapse-${item.to}`}
									aria-expanded={false}
									aria-controls={`nav-collapse-${item.to}`}
									key={`btn-nav-${index}`}
								>
									{item.title}
								</button>
							);
						})
					}
				</nav>
				{
					items.map((item,index)=>{
						return(
							<SimpleCollapse idCollapse={`nav-collapse-${item.to}`} addClass="" idParent="#accordionNav" key={`btn-nav-${index}`}>
							<div className="d-flex align-content-start flex-wrap px-5 py-2">
								{
									item.subItems.map((item,index)=>{
										return(
											<Link to={item.to} className="mr-2 my-1 px-2 py-1 border ktext-darck btn btn-outline-light" key={`btn-nav-${index}`}>
												{item.title}
											</Link>
										);
									})
								}
							</div>
							</SimpleCollapse>
						);
					})
				}
			</div>
		);
}

NavbCollapse.propTypes = {
		items: PropTypes.arrayOf(PropTypes.shape({
			to: PropTypes.string,
			title: PropTypes.oneOfType([
				PropTypes.object,
				PropTypes.string,
			]).isRequired,
			subItems: PropTypes.arrayOf(PropTypes.shape({
				title: PropTypes.oneOfType([
					PropTypes.object,
					PropTypes.string,
				]).isRequired,
				to: PropTypes.string,
			})),
		})),
};

export default NavbCollapse;