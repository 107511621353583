import React from 'react';
import { connect } from 'react-redux'
import { Add,Remove } from '../../../redux/actions/calculatorAction';
import './Calculator.scss';


const Calculator = (props: any) => {

    const {calculator} = props;

    return (<>
        <div className="row view-totales-consulta" id="view-totales-consulta">
            <div className="row-container-amount">
                <label className="lbl-greeen">Monto Bruto</label>
                <input type="hidden" id="hdd_resumen_montobruto" value={calculator.montobruto} />
                <input type="hidden" id="hdd_label_records" value="0" />
                <input type="hidden" id="hdd_calce_id_selected" value="0" />
                <input type="hidden" id="hdd_rut_selected" value="ND" />
                <p id="data-resumen_montobruto" className="lbl-greeen view-totales-consulta_montos" data-resumen_montobruto="0">
                    $ {calculator.montobruto}
                </p>
            </div>
            <div className="row-container-amount">
                <label>Monto Retención</label>
                <p id="data-resumen_montoretencion" className="view-totales-consulta_montos" data-resumen_montoretencion="0">
                    $ {calculator.montoretencion}</p>
            </div>
            
            <div className="row-container-amount" >
                <label>Monto Neto</label>
                <p id="data-resumen_montoneto" className="view-totales-consulta_montos" data-resumen_montoneto="0">
                    $ {calculator.montoneto}
                </p>
            </div>
            <div className="row-container-amount" style={{ display: 'none' }}>
                <label>Monto Desctos.</label>
                <p id="data-resumen_montodescuentos" className="view-totales-consulta_montos" data-resumen_montodescuentos="0">
                    $ {calculator.montodescuentos}
                </p>
            </div>
            <div className="row-container-amount" style={{ display: 'none' }}>
                <label>Monto a Pagar</label>
                <p id="data-resumen_montopagar" className="view-totales-consulta_montos" data-resumen_montopagar="0">
                    $ {calculator.montopagar}
                </p>
            </div>
        </div>
    </>)
}

const mapStateToProps = (state: any) => ({
    calculator: state.calculator
})

const mapDispatchToProps = {
    Add,
    Remove
}

export default connect(mapStateToProps, mapDispatchToProps)(Calculator)
