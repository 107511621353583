import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import * as QueryString from 'query-string';
import { withRouter,useHistory } from 'react-router-dom';
import { DateRangePicker, Button, FormGroup, ControlLabel, FormControl, HelpBlock, Form} from 'rsuite';

import  actions from '../../redux/actions'
import { ExportCSVFile } from '../../services/Utils';
import  * as Api from '../../api/end.points';
import Inputmask from "inputmask";

const InvoiceNCFilters = ( props:any ) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const query:any = QueryString.parse(history.location.search);
	const [inputs, setInputs]:any = useState({...query});
	const { InvoiceAction } = actions;

	if (typeof inputs.start==="undefined") {
		delete query.start;
		delete query.end;
	}
	if (typeof inputs.rut==="undefined" || inputs.rut==="") {
		delete query.rut;
		delete inputs.rut;
	}


	const SearchInformation =  () => {

		if (typeof inputs.start==="undefined") {
			delete query.start;
			delete query.end;
		}
		if (typeof inputs.rut==="undefined" || inputs.rut==="") {
			delete query.rut;
			delete inputs.rut;
		}


		delete query.page;

		history.push({
			pathname: history.location.pathname,
			search:QueryString.stringify({
				...query,
				...inputs
			}),
			state: {
				...query,
				...inputs,
				page:null
			}
		})
	}
	

	const handleInput = (e:any) =>{

		if(e.target.name==='rut'){
			var rule = e.target.value.length > 12 ? '9[9.999.999]-[9|K|k]' : '[9.999.999]-[9|K|k][99]';
			var selector = document.getElementById("rut");
			var im = new Inputmask(rule);
			im.mask(selector);
		}
		setInputs({
			...inputs,
			[e.target.name]: e.target.value
		});
	}


	return(
			<Form layout="inline" className="pb-0">
				<FormGroup>
					<ControlLabel>RUT. Prestador {<HelpBlock tooltip>RUT Prestador</HelpBlock>}</ControlLabel>
					
					<br/>
					<div className="row">
					<div className="col-12">
					<input
									id="rut"
									name="rut"
									type="rut"
									className="form-control"
									onChange={handleInput}
									placeholder="##.###.###-#"
									value={inputs.rut}
									style={{ width: '200px'}}
								/>
					
					</div>
					</div>
					
					
					
				</FormGroup>
		    <FormGroup>

		    	<Button appearance="subtle" active onClick={SearchInformation} style={{marginTop: '35px' , color: 'white' , backgroundColor: '#35485f'}}>Buscar</Button>

				{' '}
				<Button appearance="subtle" active style={{marginTop: '35px' , color: 'white' , backgroundColor: '#35485f'}} onClick={()=>{ props.GenerateCsv() }} loading={props.CsvLoading} disabled={props.CsvLoading}> Exportar (csv) </Button>
		    </FormGroup>
			</Form>
	);
}
export default withRouter(InvoiceNCFilters);