import React, { useState , useEffect , useRef } from 'react';
import { useDispatch } from 'react-redux'
import * as QueryString from 'query-string';
import { withRouter,useHistory } from 'react-router-dom';
import { DateRangePicker, Button, FormGroup, ControlLabel, FormControl, HelpBlock, Form} from 'rsuite';

import  actions from '../../redux/actions'
import { ExportCSVFile } from '../../services/Utils';
import SelectPicker  from './calculator/SelectPicker';
import moment from 'moment'; 

import  { getMotivoBaja , getTipoBaja } from '../../api/end.points';


const InvoicesFiltres = ( props:any ) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const query:any = QueryString.parse(history.location.search);
	const [inputs, setInputs]:any = useState({...query});
	const { InvoiceAction } = actions;
	const [itemstipos,setItemsTipos] = useState([]);
	const [itemsmotivos,setItemsMotivos] = useState([]);


	const actionButton=()=>{
		if (typeof inputs.start==="undefined") {
			delete query.start;
			delete query.end;
		}
		if (typeof inputs.rut==="undefined" || inputs.rut==="") {
			delete query.rut;
			delete inputs.rut;
		}
		dispatch(actions.calculatorAction.Reload(true));
		
		history.push({
			pathname: history.location.pathname,
			search:QueryString.stringify({
				...query,
				...inputs,
				page:1
			}),
			state: {
				page:1
			}
		})
	}
	const clearFilters=()=>{
		history.push({
			pathname: history.location.pathname,
			search:QueryString.stringify({}),
			state: {}
		})
		setInputs({rut:''});
		setInputs({motivobaja: ''});
		setInputs({tipobaja: ''});
		

	}

	const handleInput = (e:any) =>{
		if (e.target.name==='range') {
			let yaer:any=0;
			let month:any=0;
			let day:any=0;
			let range={};
			const data = e.target.value;
			for (var i = 0; i < data.length; ++i) {
				yaer = data[i].getFullYear();
				month = data[i].getMonth()+1;
				day = data[i].getDate();
				month = month >= 10 ? month : `0${month}`;
				day =  day >= 10 ? day : `0${day}`;
				range[i===0?'start':'end'] = `${yaer}-${month}-${day}`;
			}
			if (data.length===0) {
				delete inputs.start;
				delete inputs.end;
			}
			setInputs({
				...inputs,
				...range
			});
		} else {
			setInputs({
				...inputs,
				[e.target.name]: e.target.value
			});
		}

	}

	const setInputMotivoBaja = (value) => {

		setInputs({motivobaja:value});

	}

	const setInputTipoBaja= (value) => {

		setInputs({tipobaja:value});

	}

	

	

	useEffect(() => {
		
		const MotivoBaja = async() => {
			let aux:any = await getMotivoBaja();
			setItemsMotivos(aux);
		}
	
		const TipoBaja = async () => {
			let aux:any = await getTipoBaja();
			setItemsTipos(aux);
		}


			MotivoBaja();
			TipoBaja();
		// content
		return () => {

		};
	}, []);

	return(
			<Form layout="inline" className="pb-0">
				<FormGroup>
		      <ControlLabel>Fecha de Proceso.</ControlLabel>
		      <br/>
		      <DateRangePicker
			      value={typeof inputs.start==="undefined" ? [] : [
			      	inputs.start,
							inputs.end
			      ]}
			      onChange={(e)=>{
			      	handleInput({
			      		target:{
			      			name:'range',
			      			value:e
			      		}
			      	})
			      }}
			    />
		      <HelpBlock tooltip>Es la fecha en la que se verifico las ordenes de pago</HelpBlock>
		    </FormGroup>
		    <FormGroup>
		      <ControlLabel>RUT.</ControlLabel>
		      <br/>
		      <FormControl
		      	name="rut"
		      	value={inputs.rut}
		      	placeholder="Rut sin digito verificador"
		      	classPrefix="ml-0 rs-form-control"
		      	onChange={(e)=>{
		      	handleInput({
			      		target:{
			      			name:'rut',
			      			value:e
			      		}
			      	})
			      }}
			    />
		      <HelpBlock tooltip>Este RUT puede ser del Prestador o Profesional</HelpBlock>
		    </FormGroup>
		    <FormGroup>
				{
					props.viewadvancedfilter ?
					<>
							<div className="rs-form-group">
									<label htmlFor="">Tipos de Baja</label>
									<select className="form form-control" defaultValue={inputs.tipobaja} value={inputs.tipobaja} onChange={(e) => {setInputTipoBaja(e.currentTarget.value)}}>
									<option value="-1">Todos</option>
									{
										itemstipos.map( (item) => 
										<option key={item.value} value={item.value}>{item.label}</option> )
									}
									</select>
							</div>
							{' '}
							<div className="rs-form-group">
									<label htmlFor="">Motivo de Baja</label>
									<select className="form form-control"  value={inputs.motivobaja} defaultValue={inputs.motivobaja} onChange={(e) => {setInputMotivoBaja(e.currentTarget.value)}}>
									<option value="-1">Todos</option>
									{
										itemsmotivos.map( (item) => 
										<option key={item.value} value={item.value}>{item.label}</option> )
									}
									</select>
							</div>
							{' '}
						</>
						: <><ControlLabel className="text-white">.</ControlLabel><br /></>
				}
	
		    	<Button appearance="subtle" active onClick={actionButton}>Filtrar en Historial</Button>{' '}
		    	<Button appearance="subtle" active onClick={clearFilters}>Ver Hoy</Button>
		    	{
		    		props.calces ?
		    		<>
				    	{' '}
				    	<Button appearance="subtle" active onClick={()=>{
				    		const headers = {
								 rut_prestador: 'Rut Prestador',
								 fecha_documento: 'Fecha de Documento',
								 numero_documento: 'Número de Documento',
								 razon: 'Razón social',
								 fecha_calce: 'Fecha de calce',
								 monto_bruto: 'Monto Bruto',
								 impuesto: 'Impuesto',
								 total: 'Total',
								 key_cheque: 'Key cheque',
								 rut_profesional: 'RUT Profesional',
								 fecha_proceso: 'Fecha Proceso',
								 tipo_pago: 'Tipo Pago',
								 moonto_pagar: 'Monto a pagar',
								 tipo_dte: 'Tipo DTE',
								 empresa_tributaria: 'Empresa Tributaria',
								};
								const data = [];

								for (let i = 0; i < props.calces.length; ++i) {
									let row = props.calces[i];
									for (let i0 = 0; i0 < row.detalle.ordenes.length; ++i0) {
										let row0 = row.detalle.ordenes[i0];
										data.push({
											rut_prestador: row.rut_prestador,
											fecha_documento:  row.dte.fecha_contable,
											numero_documento: row.dte.folio,
											razon: row.dte.razon_social,
											fecha_calce: row.created_at,
											monto_bruto: row.dte.monto_bruto,
											impuesto: row.dte.monto_impuesto,
											total: row.dte.monto_impuesto+row.dte.monto_bruto,
											key_cheque: row0.KeyCheque,
											rut_profesional: row0.Rut_Profesional,
											fecha_proceso: row0.Fecha_Proceso,
											tipo_pago: row0.Tipo_Pago,
											moonto_pagar:  row0.Monto_Bruto,
											tipo_dte: row.dte.doc_type === 'BHE' ? 'Boleta' : 'Factura',
											empresa_tributaria: row0.Empresa_Tributaria,
										})
									}
								}
				    		ExportCSVFile(headers,data,'DOCUMENTOS_CALZADOS');
				    	}}>Ultimos calces(csv)</Button>

		    		</>:null
		    	}
				{
					props.viewadvancedfilter ?
					<>
						{' '}
				    	<Button appearance="subtle" active onClick={()=>{ props.GenerateCsv() }} loading={props.CsvLoading} disabled={props.CsvLoading}> Exportar (csv) </Button>
					</> : null
				}
		    </FormGroup>
			</Form>
	);
}
export default withRouter(InvoicesFiltres);