import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import moment from 'moment';
import SimpleTable from '../../components/table/SimpleTable';
import RsuitePaginationLumen from '../../components/Paginations/RsuitePaginationLumen';
import * as Api from '../../api/end.points';
import { Amount, DigitoVerificador, ExportCSVFile } from '../../services/Utils';

import InvoiceNCFilters from './InvoiceNCFilters';

const InvoiceAnulacionesBHE = ( props:any ) => {
    const history = useHistory();
	//// console.log('VISTA LIST',list.results)
	const [list, setlist] = useState({results:null});
	const [listLoading, setlistLoading] = useState(true);
	const [seletcDocument, setSeletcDocument] = useState(0);
	let queryUri:any = QueryString.parse(history.location.search);
	const [CsvLoading, setCsvLoading] = useState(false);

	const GenerateCsv = async () => {

		setCsvLoading(true);
		let query:any = QueryString.parse(history.location.search);

		const rows:any = list.results.length < 15 ? list.results : await Api.getAllBHE(query);

		const headers = {
			folio : 'Ref. Folio',
			sender_rut : 'RUT Prestador',
			sender_name : 'Razón Social',
			recieved_rut : 'RUT Receptor',
			issued_date : 'Fecha Recepción',
			accounting_date : 'Fecha Creación',
			status : 'Estado',
			total_amount : 'Monto Total',
			document_file : 'Archivo',
		};

		const data = [];

		for (let i = 0; i < rows.length; ++i) {
			let row = rows[i];
			data.push({
				folio: row.folio,
				sender_rut: row.sender_rut,
				sender_name: row.sender_name,
				recieved_rut: row.recieved_rut,
				issued_date: row.issued_date,
				accounting_date: row.accounting_date,
				status: row.status,
				total_amount: row.total_amount,
				document_file: row.document_file
			});
		}

	   	ExportCSVFile(headers,data,'ANULACIONES_BHE');

		setCsvLoading(false);

	}

    useEffect(()=>{
		const runList = async () => {
			setlistLoading(true);
			setlist({
				...list,
				results:null
			})
			let query:any = QueryString.parse(history.location.search);
			const getData:any = await Api.getBHE(query);
			setlist({...getData})
			setlistLoading(false);
		}
		runList();
	},[props,history]);

    return(<>
       <React.Fragment>
			<h5 className="py-2">
				Listado Anulaciones BHE
				<hr className="my-2"/>
			</h5>
            <InvoiceNCFilters GenerateCsv={GenerateCsv} CsvLoading={CsvLoading} />
			<SimpleTable
				messageEmpty={'No hay documentos calzados'}
				data={list.results}
				onLoadingTable={listLoading}
				trHeader={[
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Ref. Folio'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'RUT Prestador'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Razón Social'},
                    {thClassName:'samll px-2 pb-0 text-white text-center',content:'RUT Receptor'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Fecha Recepción'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Fecha Creación'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Estado'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Monto Total'},
					{thClassName:'samll px-2 pb-0 text-white text-center',content:'Archivo'},
				]}
				trBody={[
					{tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'folio'},
					{tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'sender_rut'},
                    {tdClassName:'small px-2 py-0 pt-1', attrData:'sender_name'},
                    {tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'recieved_rut'},
					{
						tdClassName:'small px-2 py-0 pt-1 text-center',
						attrData:'issued_date',
						content: (item,row) =>{
							return(
								<label>
									{moment(item).local().format('DD/MM/YYYY')}
								</label>
							)
						}
					},
                    {
						tdClassName:'small px-2 py-0 pt-1 text-center',
						attrData:'accounting_date',
						content: (item,row) =>{
							return(
								<label>
									{moment(item).local().format('DD/MM/YYYY')}
								</label>
							)
						}
					},
					{tdClassName:'small px-2 py-0 pt-1 text-center', attrData:'status'},
					{tdClassName:'small px-2 py-0 pt-1 text-right', attrData:'total_amount', content:(item)=>Amount(item)},
                    {
						tdClassName:'small px-2 py-0 pt-1 text-center',
						attrData:'document_file',
						content: (item,row) =>{
							return(
                                <a href={item} download >
                                    Descargar
                                </a>
                                )
						}
					},
				]}
			/>
			<RsuitePaginationLumen {...list}/>
		</React.Fragment>
    </>)

} 

export default InvoiceAnulacionesBHE;