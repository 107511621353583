import { types } from '../types';
import * as Api from '../../api/end.points'
import { INITIAL_STATE } from '../reducers/invoiceReducer';

export const setList = async (dispatch,loading=false) => {
	const action = {
		type: types.INVOICE_LIST,
		payload:{
			...INITIAL_STATE,
		}
	}
	if(loading){
		dispatch(action);
	}else{
		// const getData = await Api.InvoicesListing({});
		const getData:any = await Api.Shim0({});
		action.payload.list=getData;
		action.payload.listLoading=false;
		dispatch(action);
	}
};