import React, {useState, useEffect} from 'react';

import SimpleCollapse from '../../components/list.all/SimpleCollapse';
import { Amount,DigitoVerificador } from '../../services/Utils';
import moment from "moment";

import InputCheckBox from './InputCheckBox';

const InvoiceDetails = ( props:any ) => {
	const [amountBhe, setAmountBhe] = useState(0);
	const [amountOrders, setAmountOrders] = useState(0);
	const [amountDiff, setAmountDiff] = useState(0);
	const countORDENES=props.detalle.ordenes ? props.detalle.ordenes.length : 0;
	const viewcheckbox =props.checkbox ? props.checkbox  : false;
	

	useEffect(()=>{
		
		const orders = props.detalle.ordenes;
		let total = 0;
		let bhe = 0;
		for (var i = 0; i < orders.length; ++i) {
			total=total+orders[i].Monto_Bruto;
		}
		setAmountOrders(total);
		if (props.dte) {
			bhe = props.dte.monto_bruto+props.dte.monto_impuesto
			setAmountBhe(bhe);
		}
		setAmountDiff(total-bhe);
	},[props])

	return(
		<SimpleCollapse idCollapse={`details-invoce-${props.id}`} >
			<div className="d-flex justify-content-around px-0 border-top" style={{fontSize:'13px'}}>
				<div className="p-2 bd-highlight text-left">
					ORDENES({countORDENES}):{Amount(amountOrders)} 
				</div>
				<div className="p-2 bd-highlight text-left">
					BOLETA/DOCUMENTO:{Amount(amountBhe)}
				</div>
				<div className="p-2 bd-highlight text-left">
					DIFERENCIA: { 
						amountDiff === 0 ? 
							<span className="text-success">NINGUNA</span>:
								<span className="text-danger">{Amount(amountDiff)}</span>
						}
				</div>
				{
					viewcheckbox === true ? 
					<>
						<div className="p-2"></div>	
					</> : <></>
				}
			</div>
			{
				props.detalle.ordenes ? props.detalle.ordenes.map((item,index)=>{
					// console.log(item)
					return(
						<DetailsOrden key={'key-dto-'+index} item={item} index={index} dte={props.dte} checkbox={viewcheckbox} />
					);
				}) : <h2 className="text-center">Sin información</h2>
			}
		</SimpleCollapse>
	);
}

const DetailsOrden = ({item,index, dte , checkbox=false}) => {

	
	return(
		<div className="d-flex justify-content-around px-0 border-top" key={`invoice-details-${index}`}  style={{fontSize:'13px'}}>
			{
				checkbox === true ? 
				<><InputCheckBox item={item} dte={dte}/>
				</> : <></>
			}
			<div className="p-2 bd-highlight text-left">
				Key Cheque
				<br/>
				{item.KeyCheque}
			</div>
			<div className="p-2 bd-highlight text-left">
				RUT Profesional
				<br/>
				{DigitoVerificador(item.Rut_Profesional)}
			</div>
			<div className="p-2 bd-highlight text-left">
				Fecha de Proceso
				<br/>
				{moment(item.Fecha_Proceso).local().format('DD/MM/YYYY')}
			</div>
			<div className="p-2 bd-highlight text-left">
				Tipo Pago
				<br/>
				{item.Tipo_Pago}
			</div>
			<div className="p-2 bd-highlight text-left">
				Retención Efectuada
				<br/>
				{Amount(item.Retencion_Efectuada)}
			</div>
			<div className="p-2 bd-highlight text-left">
				Total Honorarios
				<br/>
				{Amount(item.Monto_Bruto)}
			</div>
		</div>
	);
}


export default InvoiceDetails