import React, { useState,useEffect } from 'react';
import {useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';

import SimpleTable from '../../components/table/SimpleTable';
import RsuitePaginationLumen from '../../components/Paginations/RsuitePaginationLumen';
import * as Api from '../../api/end.points'
import moment from "moment";

const Logs = (props:any) => {
	const [ list, setList ] = useState({
		data:[]
	});
	const [listLoading, setlistLoading] = useState(true);
	const history = useHistory();
	const query:any = QueryString.parse(history.location.search);
	useEffect(()=>{
		const runList = async () => {
			setlistLoading(true);
			setList({
				...list,
				data:null
			})
			let query:any = QueryString.parse(history.location.search);
			const getData:any = await Api.LogListing(query);
			setList({
				...getData
			})
			setlistLoading(false);
		}
		runList();
	},[props,history]);

	return(
		<div className="row">
			<div className="col-12">
				{/* <h5 className="py-2">
					Log de actividades
					<hr className="my-2"/>
				</h5> */}
			</div>
			<div className="col-12">
				<SimpleTable
					messageEmpty={'No hay documentos calzados'}
					data={list.data}
					onLoadingTable={listLoading}
					trHeader={[
						{thClassName:'samll px-2 pb-0 text-white',content:'#'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Tipo de actividad'},
						{thClassName:'samll px-2 pb-0 text-white',content:'ID usuario'},
						{thClassName:'samll px-2 pb-0 text-white',content:'RUT'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Fecha'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Accion'},
						{thClassName:'samll px-2 pb-0 text-white',content:'Descripcion'},
						{thClassName:'samll px-2 pb-0 text-white',content:'ID token'},
						{thClassName:'samll px-2 pb-0 text-white',content:'OPCIONES'},
					]}
					trBody={[
						{tdClassName:'small px-2 py-0 pt-1', attrData:'id'},
						{
						tdClassName:'small px-2 py-0 pt-1',
						attrData:'rut_prestador',
						content: (item,row) =>{
								return(
									<label>
										{row.type === 1 ? 'automatico' : 'usuario'}
									</label>
								)
						}
					},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'user.id'},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'user.rut'},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'created_at', content: (item, row) => {
							return (
								<label>{moment(item).local().format('DD/MM/YYYY HH:mm:ss')}</label>
							);
						}},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'slug'},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'json', content:(item,row)=>{
							if (row.action_id===4) {
								return(<>Nueva hora {item.hour}:{item.minute}</>);
							} else if (row.action_id = 14) {
								return (<>{row.json.message !== undefined ? row.json.message : ''}</>)
							}
							return null;
						}},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'token_id'},
						{tdClassName:'small px-2 py-0 pt-1', attrData:'options', content:(item,row)=>{
							return(
								<React.Fragment>
									<button
										className="btn btn-primary btn-sm py-0 px-1"
										type="button"
										data-toggle="collapse"
										data-target={`#details-invoce-${row.id}`}
										aria-expanded={false}
										aria-controls={`details-invoce-${row.id}`}
									>
										Sin accion
									</button>
								</React.Fragment>
							);
						}},
					]}
				/>
				<RsuitePaginationLumen {...list}/>
			</div>
		</div>
	);
}
export default Logs;