import React from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LayoutAdmin from '../layouts/LayoutAdmin';
import LayoutPublic from '../layouts/LayoutPublic';

// Pages
import InvoiceListCalce from '../pages/invoices/InvoiceListCalce';
import InvoiceListNotAmount from '../pages/invoices/InvoiceListNotAmount';
import InvoiceListNotFoundOrder from '../pages/invoices/InvoiceListNotFoundOrder';
import Config from '../pages/invoices/Config';
import Bitacora from '../pages/invoices/Bitacora';

import InvoiceListNotasCredito from '../pages/invoices/NotasCredito';
import InvoiceAnulacionesBHE from '../pages/invoices/InvoiceAnulacionesBHE';

import HomeAdmin from '../pages/home.pages/HomeAdmin';
import SingIn from '../pages/auth.pages/SingIn';
import SingInIntranet from '../pages/auth.pages/SingInIntranet';

// import { createSemicolonClassElement } from 'typescript';

const UsePageViews = (props:any) => {
  let location = useLocation();
	if(location.pathname!=='/sing/in' && location.pathname!=='/auth/intranet'){
		return (<Redirect to='/sing/in' />)
	}else{
		return(null)
	}
}
const ToClaces = (props:any) => {
  let location = useLocation();
  	if(location.pathname==='/sing/in') return (<Redirect to='/' />)	  
	if(location.pathname==='/auth/intranet'){
		return (<Redirect to='/invoices/error/montos?page=1&tipobaja=1' />)
	}else{
		return(null)
	}
}

const RouterAdmin = () => {
	const { auth } = useSelector( (state : any ) => state );
	
	return(
		<Router>
			{
				auth?.info?.isLogged ?
				<LayoutAdmin>
					<ToClaces/>
					<Switch>
						<Route exact path={['/invoices/error/montos']} component={(props) => <InvoiceListNotAmount {...props} />}/>
						<Route exact path={['/invoices/error/sin/ordenes']} component={(props) => <InvoiceListNotFoundOrder {...props} />}/>
						<Route exact path={['/invoices/calce']} component={(props) => <InvoiceListCalce {...props} />}/>
						<Route exact path={['/bitacora']} component={(props) => <Bitacora {...props} />}/>
						<Route exact path={['/config']} component={(props) => <Config {...props} />} />
						<Route exact path={['/home']} component={(props) => <HomeAdmin {...props} />}/>
						<Route exact path={['/']} component={() => <Redirect to='/invoices/error/montos?page=1&tipobaja=1' />}/>
						<Route exact path={['/notas-credito']} component={(props) => <InvoiceListNotasCredito {...props} />}/>
						<Route exact path={['/anulaciones-bhe']} component={(props) => <InvoiceAnulacionesBHE {...props} />}/>

					</Switch>
				</LayoutAdmin>
				:
				<LayoutPublic>
					<UsePageViews/>
					<Switch>
						<Route exact path={['/auth/intranet']} component={(props) => <SingInIntranet {...props} />}/>
						<Route exact path={['/','/sing/in']} component={(props) => <SingIn {...props} />}/>
					</Switch>
				</LayoutPublic>
			}
		</Router>
	);
};
export default RouterAdmin;
