import React from 'react';
// import { Link } from 'react-router-dom';

// import Imgs from '../components/media/Imgs';

const LayoutPublic = ( props:any ) => {
	return(
		<React.Fragment>
			<div className="container public-layout">
			{props.children}
			</div>
		</React.Fragment>
	);
}
export default  LayoutPublic;