import React from 'react';


const HomePublic = ( props:any ) => {
	return(
		<React.Fragment>
			<h1 className="text-center py-5">Home Admin</h1>
		</React.Fragment>
	);
}
export default HomePublic;