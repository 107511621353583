export const InfoSession = () => {
	return {
    authorization: localStorage.getItem('authorization'),
    fullName: localStorage.getItem('full_name'),
    isLogged: ('authorization' in localStorage),
  };
}
export const OpenSession = (data : any) => {
	return {
    authorization: localStorage.setItem('authorization', data.authorization),
    fullName: localStorage.setItem('full_name',data.full_name),
    status: localStorage.setItem('status',data.status),
    username: localStorage.setItem('username',data.username),
  };
}
export const UpdateSession = (data : any) => {
  return {
    fullName: localStorage.setItem('full_name',data.full_name),
    status: localStorage.setItem('status',data.status),
    username: localStorage.setItem('username',data.username),
  };
}

export const CloseSession = () => {
	localStorage.clear();
}