import React from 'react';
import { Provider } from 'react-redux'
import { store } from './redux/store';

import RouterAdmin from './router/RouterAdmin';
import RouterPublic from './router/RouterPublic';


function App() {
	const auth = true;
	return (
		<Provider store={store}>
			<div className="App">
				{ 
					auth ?
					<RouterAdmin /> :
					<RouterPublic />
				}
			</div>
		</Provider>
	);
}

export default App;
