import { types } from '../types';
import { InfoSession, CloseSession } from '../../api/session';

export const login = () => {
	return {
		type: types.login,
		payload:{
			infoSession:InfoSession(),
		}
	}
};

export const logout = () => {
	CloseSession();
	return {
		type: types.login,
		payload:{
			infoSession:InfoSession(),
		}
	}
};
