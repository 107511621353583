import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import { useSelector, useDispatch } from 'react-redux'

//import  actions from '../../redux/actions'

// components
import SimpleTable from '../../components/table/SimpleTable';

// services
import { Amount, DigitoVerificador} from '../../services/Utils';

// partials
import InvoiceDetails from './InvoiceDetails';
import InvoicesFiltres from './InvoicesFiltres';

import * as Api from '../../api/end.points'
import { createSemanticDiagnosticsBuilderProgram } from 'typescript';

const InvoiceListNotFoundOrder = ( props:any ) => {
	const [list, setlist] = useState({results:null});
	const [icon, setIcon] = useState([]);
	const [listLoading, setlistLoading] = useState(true);
	const loadList = async () => {
		const getData = await Api.Shim2({});
		setlistLoading(false);
		setlist(getData[0])
	}
	useEffect(() => {
	  loadList()
	}, [])
	if(list.results!=null){
		for (var i = 0; i < list.results.length; ++i) {
	 		list.results[i]['trExtra'] = (row:any) => { return(<InvoiceDetails {...row}/>) }
	 	}
	}
	const cambiarIcono = (row) => {
		const resul = icon.find(w => w === row.id);
		if (resul !== undefined){
			icon.splice(icon.indexOf(resul), 1)
		}else{
			icon.push(row.id);
		}
		setIcon([...icon]);
	}
	return(
		<React.Fragment>
			<h5 className="py-2">
				Documentos sin ordenes de pago
				<hr className="my-2"/>
			</h5>
			<InvoicesFiltres/>
			<SimpleTable
				messageEmpty={'No hay documentos que le falte ordenes de pagos'}
				data={list.results}
				onLoadingTable={listLoading}
				trHeader={[
					{thClassName:'samll px-2 pb-0 text-white',content:'#'},
					{thClassName:'samll px-2 pb-0 text-white',content:'RUT Prestador'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Fecha de Documento'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Número Documento'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Razón Social'},
					{thClassName:'samll px-2 pb-0 text-white',content:'Monto Bruto'},
					{thClassName:'samll px-2 pb-0 text-white',content:'OPCIONES'},
				]}
				trBody={[
					{tdClassName:'small px-2 py-0 pt-1', attrData:'id'},
					{
						tdClassName:'small px-2 py-0 pt-1',
						attrData:'rut_prestador',
						content: (item,row) =>{
							if(row.user){
								return(
									<label>
										<Link to={`/detalle/usuario/${row.user.id}`}>
											{item}
										</Link>
									</label>
								)
							}else{
								return(
									<label>
										{DigitoVerificador(row.rut_prestador)}

									</label>
								)
							}
						}
					},
					{tdClassName:'small px-2 py-0 pt-1', attrData:'dte.fecha_contable'},
					{
						tdClassName:'small px-2 py-0 pt-1',
						attrData:'dte',
						content: (item,row) =>{
								return(
									<label>
										<a href="#SimpleModal" data-toggle="modal" data-target="#SimpleModal">
											{item.folio}
										</a>
									</label>
								)
						}
					},
					{tdClassName:'small px-2 py-0 pt-1', attrData:'dte.razon_social'},
					{tdClassName:'small px-2 py-0 pt-1', attrData:'dte.monto_bruto', content:(item)=>Amount(item)},
					{tdClassName:'small px-2 py-0 pt-1', attrData:'options', content:(item,row)=>{
						const expand = icon.find(w => w === row.id) !== undefined
						return(
							<React.Fragment>
								<button
									className="btn btn-primary btn-sm py-0 px-1"
									type="button"
									data-toggle="collapse"
									data-target={`#details-invoce-${row.id}`}
									aria-expanded={false}
									aria-controls={`details-invoce-${row.id}`}
									onClick = {(e)=>cambiarIcono(row)}
								>
									<i className="fa fa-plus"/>
								</button>
							</React.Fragment>
						);
					}},
				]}
			/>
		</React.Fragment>
	);
}
export default InvoiceListNotFoundOrder;