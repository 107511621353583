export function findObject(o:any, s:any) {
	const newObject = {
		byString:function(o:any, s:any) {
			s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
			s = s.replace(/^\./, '');           // strip a leading dot
			var a = s.split('.');
			for (var i = 0, n = a.length; i < n; ++i) {
				var k = a[i];
				if (k in o) {
					o = o[k];
				} else {
					return;
				}
			}
			return o;
		}
	}
	try {
		return newObject.byString(o, s)
	} catch (error) {
		//console.error(error);
		return null
		// expected output: ReferenceError: nonExistentFunction is not defined
		// Note - error messages will vary depending on browser
	}
	
}
export function Amount(value) {
	const formatter = new Intl.NumberFormat('es-CL', {
		style: 'currency',
		currency: 'CLP',
	});
	return formatter.format(value);
}

export function HashString() {
	return Math.random().toString(36).substring(2);
}

export function DigitoVerificador(T)    //digito verificador
{  	var rut=T;
    var M=0,S=1;
	  for(;T;T=Math.floor(T/10))
      S=(S+T%10*(9-M++%6))%11;
      return `${rut}-${(S?S-1:'k')}`;
 }

export function convertToCSV(objArray) {
 const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
 let str = "";for (let i = 0; i < array.length; i++) {
  let line = "";
  for (let index in array[i]) {
   if (line != "") line += ",";line += array[i][index];
  }str += line + "\r\n";
 }return str;
}
// for UTF-16
export function ExportCSVFile(headers, items, fileName) {
	if (headers) {
		items.unshift(headers);
	}
	const jsonObject = JSON.stringify(items);
	const csv = convertToCSV(jsonObject);
 	var cCode,
	bArr = [];
	bArr.push(255, 254);
	for (var i = 0; i < csv.length; ++i) {
	cCode = csv.charCodeAt(i);
	bArr.push(cCode & 0xff);
	bArr.push((cCode / 256) >>> 0);
	}
	const exportName = fileName + ".csv" || "export.csv";
	const blob = new Blob([new Uint8Array(bArr)], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		navigator.msSaveBlob(blob, exportName);
	} else {
		const link = document.createElement("a");
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", exportName);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}