import React , {useState   } from 'react';
import { Modal , Button  } from 'react-bootstrap';
import { Notification } from 'rsuite';
import  { UpdateMotivoBaja } from '../../../api/end.points';

import SelectPicker  from './SelectPicker';
import  { getMotivoBaja  } from '../../../api/end.points';


const ModalTramaReference = (props:any) => {

    const [open, setOpen] = useState(false);
    const [btnDisabled , setBtnDisabled] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        
        let id:any = document.getElementById('modal_sendmail_'+props.row.id);
        id.style.display = 'none';
        setOpen(false);
    
    }



    const [inputs, setInputs] = useState(
            {motivobaja: '' , identificador: ''});

    const handleClickBajaManual = async () => {


        if(inputs.motivobaja === ''){
            Notification['error']({
                title: 'Debe seleccionar un Motivo de Baja.',
                placement: 'topStart',
            });

            return false;
        }
        
        setInputs({
			...inputs,
			['identificador']: props.row.id
		});


     
        setBtnDisabled(true);

        let send:any = await UpdateMotivoBaja({motivobaja: inputs.motivobaja , id : props.row.id});
        
        if(send.status == 'success'){

            Notification['success']({
				title: 'Baja Manual exitosa.',
				placement: 'topStart',
			});
            
            setTimeout(() => { 
                window.location.reload(); 
            }, 2000);

            handleClose();

        } else {

            Notification['error']({
				title: 'No se pudo dar de baja',
				placement: 'topStart',
			});

        }

        setBtnDisabled(false);


    }


    const handleClickOpen = async () => {


        handleOpen();


    }


    const MotivoBaja = async() => {

		let aux:any = await getMotivoBaja();
		return aux;
	}

    const setInputMotivoBaja = (value:any) => {

		setInputs({
			...inputs,
			['motivobaja']: value
		});

        setBtnDisabled(false);

	}

    const beautifull = (data:any) => {

        var obj = JSON.parse( data );
        var pretty = JSON.stringify(obj, undefined, 4);
        return pretty;
    }

    return(<>{' '}
            {
                props.row.is_nc == 1 ||  props.row.is_bhe == 1 ? 
                <>
                    <Button className="btn btn-success btn-sm py-0 px-1"
                    type="button" 
                    onClick={handleClickOpen}> <i className="fa fa-download"/></Button>
                    <Modal show={open} onHide={handleClose} centered keyboard={true} key={props?.row?.id}  id={`modal_sendmail_${props?.row.id}`}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="">Respuesta de SIMONVE</label>
                                <textarea name="" id="" cols={50} rows={25}>
                                    {
                                        beautifull(props.row.reference_trama)
                                    }
                                </textarea>
                            </div>
                        </div>
                        <br />
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={handleClose} className="btn-danger" >
                        Salir
                    </Button>
                    </Modal.Footer>
                </Modal>
                </> : ''
            }
            
    </>)
}

export default ModalTramaReference;