import React , {useState , useEffect   } from 'react';
import { FormGroup , ControlLabel , HelpBlock , SelectPicker } from 'rsuite';
import  './SelectPicker.scss';

const SelectLoad  = (props:any) => {
    const [items, setItems] = useState([]);
    
  
    const renderMenu = menu => {
      if (items.length === 0) {
        return (
          <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
             Cargando...
          </p>
        );
      }
      return menu;
    };
    useEffect(() => {

      const updateData = async () => {

        if (items.length === 0) {
          let aux = await props.functiondata();
          setItems(aux);
        }
      };

      updateData();

    }, []);
  
    return (
      <>
      <FormGroup>
				<ControlLabel >{props.label}</ControlLabel>
				<br />
				<SelectPicker
          placeholder="Seleccionar"
          data={items}
          style={{ width: 224 }}
          //onOpen={updateData}
          onChange={(value, event) => { props.setInput(value)}}
          
          /*onOpen={}
        onSearch={updateData}
        renderMenu={renderMenu}*/
      />
				<HelpBlock tooltip>{props.tooltip}</HelpBlock>
				</FormGroup>
      </>
    );
  };
  
export default SelectLoad;