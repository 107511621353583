import React, { useState, useRef , useEffect} from 'react';
import { DatePicker, Button, Notification , InputNumber , InputGroup } from 'rsuite';
import * as Api from '../../api/end.points'
import Logs from './Logs'

const Config = () => {
	const [inputs, setInputs] = useState({});
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [btnDisabled2, setBtnDisabled2] = useState(false);
	const [btnAmountDisabled, setBtnAmountDisabled] = useState(false);
	const [inputsamount, setInpuntsAmount] =  useState({value: "1", id: 3});
	const [loadingAmount, setLoadingAmount] = useState(true);

	// ref
	const fromCron = useRef(null);
	const inputRef = useRef(null);

	const handleSubmit = async (e:any) =>{
		e.preventDefault();
		setBtnDisabled(true);
		const data:any = await Api.SetCron(inputs);
		if (data.session) {
		}
		// console.log(data)
		if (data.validations != null) {
			const validate = data.validations;
			// console.log('api',validate)
			Api.LumenForm.validate(fromCron.current, validate);
		}
		setBtnDisabled(false);
	}
	const BtnPayroll = async () =>{
		setBtnDisabled2(true);
		await Api.GeneratePayroll({});
		Notification['success']({
			title: 'Nomina generada!',
			placement: 'bottomEnd',
			//duration: 20000,
		});
		setBtnDisabled2(false);
	}
	const handleInput = (e:any) =>{
		// console.log(e)
		setInputs({
			...inputs,
			[e.target.name]: e.target.value
		});
	}

	const descargar = async () => {
		const response:any = await Api.FindBhes({});
		if (Array.isArray(response)) {
			for (let i = 0; i < response.length; ++i) {
				alert(response[i].message)
			}
		}else{
			alert('hubo un error')
		}
	}

	const [configEnable, setConfigEnable] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	const handleConfigLogin = async (e:any) =>{
		e.preventDefault();
		const data:any = await Api.SignInConfig(inputs);
		console.info(data);

		if (data.error) {
			setErrorMessage(true);
			setConfigEnable(false);
		} else {
			setErrorMessage(false);
			setConfigEnable(true);
		}
		
	}

	const handleMinus = () => {
		inputRef.current.handleMinus();
	  };
	  const handlePlus = () => {
		inputRef.current.handlePlus();
	  };

	

	const handleSubmitMount = async(e:any) => {
		e.preventDefault();
		setBtnAmountDisabled(true);

		const data:any = await Api.UpdateAmountNomina(inputsamount);

		if (data.status === 'error') {
			Notification['error']({
				title: data.message,
				placement: 'bottomEnd',
				//duration: 20000,
			});

		} else {
			Notification['success']({
				title: data.message,
				placement: 'bottomEnd',
				//duration: 20000,
			});
		}

		setBtnAmountDisabled(false);
	} 

	useEffect(()=>{
		const runAmountNomina = async () => {
			const data:any = await Api.getAmountNomina(3);
	
			setInpuntsAmount({
				...inputsamount,
				"value": data.value
			});

			setLoadingAmount(false);
		}
		runAmountNomina();

	},[]);

	return( configEnable ?

		<div className="row">
			<div className="col-12">
				<h5 className="py-2">
					Configuración
					<hr className="my-2"/>
				</h5>
			</div>
			<div className="col-4">
					<form className="form-inline" ref={fromCron} onSubmit={handleSubmit}>
						<h6 className="py-2 mr-4">
							CRON 
						</h6>
						
						<div className="form-group mb-2">
								<DatePicker name="hour" format="HH:mm" ranges={[]} onChange={(date) => {
									const getHours = date.getHours();
									const getMinutes = date.getMinutes();
									handleInput({
											target:{
												name:'hour',
												value:`${getHours}:${getMinutes}`
											}
										});
								}}/>
						</div>
					  <Button appearance="link" type="submit" disabled={btnDisabled} >Actualizar</Button>
					</form>

					<br/>
					<h6 className="py-2">
						Nóminas
						<hr className="my-2"/>
					</h6>
					<Button type="button" disabled={btnDisabled} onClick={()=>BtnPayroll()}>Generar nomina</Button>
					<br/><br/>
					<h6 className="py-2">
						Boletas & Facturas
						<hr className="my-2"/>
					</h6>

			    	<Button appearance="primary" onClick={descargar}>Descargar</Button>
			</div>
			<div className="col-12">
				<form className="form-inline" ref={fromCron} onSubmit={handleSubmitMount}>
						<div className="form-group mb-2">
							<label className="mr-2">Monto ($) Corte Nómina</label>
							<div style={{width: 250}}>
								<InputGroup>
									<InputGroup.Button onClick={handleMinus}>-</InputGroup.Button>
									<InputNumber
									className={'text-center custom-input-number'}
									ref={inputRef}
									min={1}
									step={0.01}
									defaultValue={inputsamount.value}
									onChange={(value) => {setInpuntsAmount({
										...inputsamount,
										"value": ""+value
									});}}
									/>
									<InputGroup.Button onClick={handlePlus}>+</InputGroup.Button>
								</InputGroup>
     						</div>
						</div>
						<Button appearance="link" type="submit" disabled={btnAmountDisabled} >Actualizar</Button>
				</form>
			</div>
			<div className="col-12">
				<Logs/>
			</div>
		</div> : <div className="loginConfig">
			<h5>Para cambiar la configuración debe ingresar la contraseña</h5>

			{ errorMessage ? <div className="alert alert-danger">
		  		Contraseña no válida
			</div> : ''}

			<hr/>
			<form className="col-lg-2 bg-white rounded"  onSubmit={handleConfigLogin}>
				<div className="mb-3">
					<label className="form-label">Contraseña</label>
					<input
						name="password"
						type="password"
						className="form-control"
						onChange={handleInput}
						placeholder="Contraseña"
					/>
				</div>
				{/*<div className="mb-3 form-check">
					<input type="checkbox" className="form-check-input" id="exampleCheck1"/>
					<label className="form-check-label">Recuerdame</label>
				</div>*/}
				<button type="submit" className="btn btn-primary w-100" disabled={btnDisabled}>Ir a configuración</button>
			</form>
		</div> 
	);
}
export default Config;